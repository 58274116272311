import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// 提现流水
export default {
    /**
     * @description: 提现-流水列表(分页)
     * @return {void}
    */
    'POST/cost_cash_flows/page': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 生成提现-流水
     * @return {void}
    */
    'POST/cost_cash_flows/refresh': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows/refresh`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 导出-提现流水
     * @return {void}
    */
    'POST/cost_cash_flows/export': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows/export`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 新增-提现流水
     * @return {void}
    */
    'POST/cost_cash_flows': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 修改-提现流水
     * @return {void}
    */
    'PATCH/cost_cash_flows': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 批量删除-提现流水
     * @return {void}
    */
    'DELETE/cost_cash_flows': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows`, preParams(params, extra, 'delete'))
    },
    /**
     * @description: 核对提现/流水表导出
     * @return {void}
    */
    'POST/cost_cash_flows/export_diff': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows/export_diff`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 核对提现表
     * @return {void}
    */
    'POST/cost_cash_flows/check_cash': (params: any, extra?: Extra) => {
        return axios(`/cost_cash_flows/check_cash`, preParams(params, extra, 'post'))
    },

    /**
     * @description: 批量锁定/解锁
     * @param {boolean} locked 锁定/解锁
     * @param {array} ids
     * @return {void}
    */
    'POST/cost_cash_flows/batch_locked': (params: {ids: number[], locked: boolean}, extra?: Extra) => {
      return axios(`/cost_cash_flows/batch_locked`, preParams(params, extra, 'post'))
    },
}