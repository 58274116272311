import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 客户管理同步
   * @return {void}
   */
  'POST/customer_basic_info/sync': (params: any, extra?: Extra) => {
    return axios(`/customer_basic_info/sync`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取财务客户列表
   * @return {void}
   */
  'POST/customer/page': (params: any, extra?: Extra) => {
    return axios(`/customer/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取财务客户详情
   * @param {string} id
   * @return {void}
   */
  'GET/customer/page/{id}': (params: {id: string}) => {
    return axios.get(`/customer/page/${params.id}`)
  },
}