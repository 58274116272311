import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 银行流水模板
   * @return {array}
   */
  'GET/financial_flows/get_upload_enum': () => axios.get(`/financial_flows/get_upload_enum`),

  /**
   * @description: 银行流水_上传回单
   * @return {array}
   */
  'POST/financial_flows/upload_receipt': (params: {id: number, file: File}, extra: Extra) => {
    return axios(`/financial_flows/upload_receipt`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 银行账户信息-排序
   * @return {array}
   */
  'POST/company/bank_account/sort': (params: {id: number, file: File}, extra: Extra) => {
    return axios(`/company/bank_account/sort`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 银行流水_上传文件批量修改paypal备注
   * @return {array}
   */
  'PATCH/financial_flows/batch_paypal_remarks': (params: {id: number, file: File}, extra: Extra) => {
    return axios(`/financial_flows/batch_paypal_remarks`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 银行流水_修改新展银行收付方名称
   * @return {array}
   */
  'PATCH/financial_flows/batch_bosc_cost_to': (params: {id: number, file: File}, extra: Extra) => {
    return axios(`/financial_flows/batch_bosc_cost_to`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 银行流水_上传外汇凭证
   * @return {array}
   */
  'POST/financial_flows/upload_business_voucher': (params: {id: number, file: File}, extra: Extra) => {
    return axios(`/financial_flows/upload_business_voucher`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 银行流水_获取凭证
   * @return {array}
   */
  'GET/bank_receipt/download_voucher': (params: {id: number}, extra: Extra) => {
    return axios.get<any>(`/bank_receipt/download_voucher`, preParams(params, extra, 'get'))
  },
  /**
   * @description: 银行账户_汇率_导出
   * @return {array}
   */
  'POST/exchange_rates/download': (params:any, extra: Extra) => {
    return axios(`/exchange_rates/download`, preParams(params, extra, 'post'))
  }
}