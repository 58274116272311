import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27f68cf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-12" }
const _hoisted_2 = { class: "font-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sidebar_item = _resolveComponent("sidebar-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_ctx.singleItem && (!_ctx.onlyOneChild.children || _ctx.onlyOneChild.noShowingChildren ))
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.resolvePath(( _ctx.onlyOneChild.meta && _ctx.onlyOneChild.meta.path)|| _ctx.onlyOneChild.path)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, {
            index: _ctx.resolvePath(( _ctx.onlyOneChild.meta && _ctx.onlyOneChild.meta.path) || _ctx.onlyOneChild.path),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshHandle(_ctx.item)))
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({'english-title':_ctx.onlyOneChild.meta.englishTitle?true:false})
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.onlyOneChild.meta.title), 1),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.onlyOneChild.meta.englishTitle), 1)
              ], 2)
            ]),
            default: _withCtx(() => [
              (_ctx.onlyOneChild.meta.icon && _ctx.showIcon)
                ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.onlyOneChild.meta.icon)))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.onlyOneChild.meta.title === '审批' && _ctx.$store.getters.approveNumber !== 0)
                ? (_openBlock(), _createBlock(_component_el_badge, {
                    key: 1,
                    value: _ctx.$store.getters.approveNumber,
                    class: "badgeNumber"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["index"])
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: _ctx.resolvePath(_ctx.item.path)
      }, {
        title: _withCtx(() => [
          (_ctx.item.meta && _ctx.item.meta.icon)
            ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.meta.icon)))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({'english-title':_ctx.onlyOneChild.meta.englishTitle?true:false})
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.item.meta.title), 1),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.meta.englishTitle), 1)
          ], 2)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_sidebar_item, {
              key: child.path,
              item: child,
              "base-path": _ctx.resolvePath(child.path),
              "show-icon": false
            }, null, 8, ["item", "base-path"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["index"]))
}